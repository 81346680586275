<div class="container">
  <div class="top-container">
    
    <mat-form-field color="accent" appearance="outline" class="search-input">
      <mat-label>{{'cameraSettings.search' | translate}}</mat-label>
      <mat-icon color="accent" matSuffix>search</mat-icon>
      <input matInput (textChange)="onTextChange($event)" />
  </mat-form-field>  

    <button mat-mini-fab color="primary" class="button" (click)="openDialog()">
      <mat-label>
        <mat-icon>add</mat-icon>
      </mat-label>
    </button>
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>{{'cameraSettings.code' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.code}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef>{{'cameraSettings.desc' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.description}}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="watchzone">
        <th mat-header-cell *matHeaderCellDef>{{'cameraSettings.watch' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.watch_zone}}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef>{{'cameraSettings.loc' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.location.longitude !== 0">{{element.location.longitude}} / {{element.location.latitude}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="threshold">
        <th mat-header-cell *matHeaderCellDef>{{'cameraSettings.threshold' | translate}}</th>
        <td mat-cell *matCellDef="let element">{{element.thresholds.warning_level}} /
          {{element.thresholds.critical_level}}</td>
      </ng-container>

      <ng-container matColumnDef="isComplete">
        <th mat-header-cell *matHeaderCellDef>{{'cameraSettings.status' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <span [ngStyle]="isCompleted(element)" class="dot"></span>
        </td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>{{'cameraSettings.active' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [checked]="element.is_active" (change)="slideToggled(element)"></mat-slide-toggle>
        </td>
      </ng-container>
      <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>{{'cameraSettings.opt' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <button color="accent" mat-icon-button (click)="openDialog(element, element.isCompleted)">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [length]="dataSource?.data.length" [pageSize]="10" [pageSizeOptions]="pageSize"
    aria-label="Select page of cameras" class="mat-elevation-z8">
  </mat-paginator>
  </div>
</div>