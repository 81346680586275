<mat-toolbar class="nav-header">
  <img class="logo-header" src = "../../../../../assets/images/logo-countify-headernav.svg" alt="Countify logo"/>

  <div class="align-center">
    <div class="select">
      <mat-form-field class="languageSelect" appearance="outline">
        <mat-select #langSelect (selectionChange)="changeLang(langSelect.value)" [(value)]="selectedLanguage">
          <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">{{ lang }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span class="text-account">{{ authService.user()?.username }}</span>
    <button
      mat-icon-button
      *ngIf="authService.user()?.username"
      [matMenuTriggerFor]="menu"
      aria-label="User menu">
      <mat-icon class="icon-blue">account_circle</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logOut()">
        <mat-icon class="icon-blue">logout</mat-icon>
        <span>{{ "logout" | translate }}</span>
      </button>
      <button mat-menu-item [routerLink]="changePassword()">
        <mat-icon class="icon-blue">build</mat-icon>
        <span>{{ "change password" | translate }}</span>
      </button>
    </mat-menu>
  </div>
</mat-toolbar>

<mat-drawer-container class="side-nav-elements" autosize>
  <mat-drawer class="side-nav-background" mode="side" opened>
    <mat-list *ngFor="let menuItem of navItems">
      <div class="nav-card" [routerLinkActive]="['active-item']">
        <div class="nav-icon">
          <button
          class="button-sidenav"
            mat-icon
            [routerLink]="menuItem.RoutingPath"
            routerLinkActive="active-item">
            <mat-icon
            class="icon-blue"
              matBadge="{{ menuItem.BadgeValue ?? '-' }}"
              matBadgeHidden="{{ menuItem.HideBadge === undefined ? true : menuItem.HideBadge() }}"
              *ngIf="menuItem.Icon">
              {{ menuItem.Icon }}
            </mat-icon>
            <label class="nav-text">{{ menuItem.Name | translate }}</label>
            <mat-icon
              matBadge="{{ menuItem.BadgeValue ?? '-' }}"
              matBadgeHidden="{{ menuItem.HideBadge === undefined ? true : menuItem.HideBadge() }}"
              *ngIf="menuItem.svgIcon"
              svgIcon="{{ menuItem.svgIcon }}"></mat-icon>
          </button>
        </div>
      </div>
    </mat-list>
  </mat-drawer>
  <main><router-outlet></router-outlet></main>
</mat-drawer-container>

<mat-toolbar class="nav-footer">
  <span class="space-elements-header"></span>
  <span class="text-footer">
    Developed by Hertaverse S.L.© 2024 |
    <a class="text-link-footer" target="_blank" href="https://hertaverse.ai/">www.hertaverse.ai</a>
  </span>
</mat-toolbar>
