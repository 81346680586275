<div class="container-dialog">
    <div class="title-header-container">
        <div class="title-head">{{"register" | translate}} {{"camera" | translate}}</div>
    </div>

    <form class="container-form" [formGroup]="cameraForm" (ngSubmit)="apply()">

        <div class="row-container">
            <div class="column-container left">
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>{{'cameraSettings.code' | translate}}</mat-label>
                    <input matInput formControlName="code" [readonly]="lockCode">
                </mat-form-field>
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>{{'cameraSettings.desc' | translate}}</mat-label>
                    <input matInput formControlName="desc">
                </mat-form-field>
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>{{'cameraSettings.watch' | translate}}</mat-label>
                    <input matInput formControlName="watchzone">
                </mat-form-field>
                <div class="title-card">{{'cameraSettings.threshold' |
                    translate}}</div>
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>{{'warningLevel' | translate}}</mat-label>
                    <input matInput formControlName="warning">
                </mat-form-field>
                <div *ngIf="cameraForm.controls['warning'].invalid && (cameraForm.controls['warning'].dirty || cameraForm.controls['warning'].touched)" class="alert">
                    <div *ngIf="cameraForm.controls['warning'].errors.pattern">
                    Please enter a number
                  </div>
                </div>
                <mat-form-field appearance="outline" color="accent">
                    <mat-label>{{'criticalLevel' | translate}}</mat-label>
                    <input matInput formControlName="critical">
                </mat-form-field>
                <div *ngIf="cameraForm.controls['critical'].invalid && (cameraForm.controls['critical'].dirty || cameraForm.controls['critical'].touched)" class="alert">
                    <div *ngIf="cameraForm.controls['critical'].errors.pattern">
                    Please enter a number
                  </div>
                </div>
            </div>

            <mat-divider [vertical]="true"></mat-divider>

            <div class="column-container right">
                <div class="subtitle-card">{{"clickOnTheMap" | translate}}</div>
                <google-map width="100%" height="272px" [center]="centerPosition" [options]="optionsGmaps" class="m20"
                    (mapClick)="addMarker($event)">
                    <map-marker [position]="markerPosition" [options]="markerOptions" />
                </google-map>

                <div class="location">
                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>{{'cameraSettings.long' | translate}}</mat-label>
                        <input matInput formControlName="long">
                    </mat-form-field>
                    <mat-form-field color="accent" appearance="outline">
                        <mat-label>{{'cameraSettings.lat' | translate}}</mat-label>
                        <input matInput formControlName="lat">
                    </mat-form-field>

                    <button class="button-search" mat-icon-button color="accent" (click)="searchLocation()" type="button">
                        <mat-icon>search</mat-icon>
                    </button>

                </div>
                <div *ngIf="(cameraForm.controls['long'].invalid && (cameraForm.controls['long'].dirty || cameraForm.controls['long'].touched)) ||
                            (cameraForm.controls['lat'].invalid && (cameraForm.controls['lat'].dirty || cameraForm.controls['lat'].touched))" class="alert">
                    <div *ngIf="cameraForm.controls['long'].errors.pattern || cameraForm.controls['lat'].errors.pattern">
                    Please enter a number
                  </div>
                </div>
            </div>
        </div>

        <div class="button-row">
            <button mat-raised-button class="clear-buttons" type="button" (click)="closeDialog()">{{"Cancel" |
                translate}}</button>
            <button mat-raised-button color="accent" class="apply-buttons" type="submit" [disabled]="isValid()">{{"Apply" | translate}}</button>
        </div>
    </form>
</div>