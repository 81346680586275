import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from './@shared/common-environment/common-environment';
import { environment as appEnvironment } from '../environments/environment';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  protected readonly environment = environment;

  constructor(
    private router: Router,
    private translateService: TranslateService
  ) {
    console.log('Version 1.0.0');
    this.translateService.setDefaultLang(this.getLang());
  }

  ngOnInit() {
    this.loadGoogleAnalytics(); // Initialize Google Analytics
    this.loadHotjar(); // Initialize Hotjar

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && typeof gtag === 'function') {
        gtag('config', appEnvironment.googleAnalyticsKey, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  getLang() {
    let browserLang = navigator.language;
    if (browserLang.indexOf('-') !== -1) {
      browserLang = browserLang.split('-')[0];
    }
    if (browserLang !== 'es' && browserLang !== 'en') return 'en';

    return browserLang;
  }

  loadGoogleAnalytics() {
    if (appEnvironment.googleAnalyticsKey) {
      const scriptElement = document.createElement('script');
      scriptElement.src = `https://www.googletagmanager.com/gtag/js?id=${appEnvironment.googleAnalyticsKey}`;
      scriptElement.async = true;
      document.head.appendChild(scriptElement);

      scriptElement.onload = () => {
        (window as any).dataLayer = (window as any).dataLayer || [];
        function gtag(...args: any[]) {
          (window as any).dataLayer.push(args);
        }
        gtag('js', new Date());
        gtag('config', appEnvironment.googleAnalyticsKey);
      };
    }
  }

  // Method to load Hotjar dynamically
  loadHotjar() {
    const hotjarId = appEnvironment.hotjarId;
    const hotjarSv = appEnvironment.hotjarSv;

    if (hotjarId && hotjarSv) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = { hjid: hotjarId, hjsv: hotjarSv };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }
}
