<svg width="562" height="290" viewBox="0 0 562 290" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="countify_logo">
    <g id="random_pin">
    <path id="Vector" d="M170.66 86.9698L178.96 64.5698L201.55 72.2398L198.9 106.37L170.66 86.9698Z" fill="#02BEBE" class="svg-elem-1"></path>
    <path id="Vector_2" d="M157.24 89.5298H123L117.09 112.72L140.12 119.16L157.24 89.5298Z" fill="#275C9A" class="svg-elem-2"></path>
    <path id="Vector_3" d="M163.86 59.98L141.8 68.8L132.09 46.93L158.57 25.77L163.86 59.98Z" fill="#02BEBE" class="svg-elem-3"></path>
    </g>
    <g id="counti">
    <path id="Vector_4" d="M240.76 183.8C235.75 186.19 230.25 187.39 224.7 187.33C221.39 187.54 218.07 187.06 214.96 185.92C211.85 184.78 209 183 206.61 180.71C204.22 178.41 202.33 175.64 201.07 172.58C199.81 169.52 199.2 166.22 199.28 162.91C199.21 159.37 199.88 155.86 201.24 152.59C202.6 149.32 204.63 146.38 207.2 143.94C209.77 141.5 212.81 139.62 216.14 138.42C219.47 137.22 223.02 136.73 226.55 136.97C231.65 136.79 236.71 137.98 241.2 140.41L239.35 144.38C235.32 142.18 230.79 141.08 226.2 141.21C223.34 140.98 220.47 141.33 217.75 142.26C215.03 143.18 212.54 144.65 210.41 146.57C208.28 148.49 206.57 150.83 205.38 153.44C204.19 156.05 203.55 158.87 203.5 161.74C203.45 164.61 203.98 167.45 205.07 170.1C206.16 172.75 207.78 175.15 209.83 177.15C211.88 179.15 214.32 180.71 217 181.74C219.68 182.76 222.54 183.23 225.41 183.1C230.24 183.12 235.01 182.03 239.36 179.93L240.76 183.8Z" fill="#02BEBE" class="svg-elem-4"></path>
    <path id="Vector_5" d="M254.09 162.37C253.93 165.09 254.34 167.8 255.27 170.36C256.21 172.91 257.66 175.25 259.54 177.22C261.42 179.19 263.68 180.75 266.19 181.81C268.7 182.87 271.4 183.4 274.12 183.37C276.92 183.41 279.69 182.88 282.27 181.8C284.85 180.72 287.18 179.12 289.11 177.1C291.04 175.08 292.53 172.68 293.49 170.06C294.45 167.44 294.86 164.64 294.69 161.86C294.66 159.2 294.1 156.57 293.04 154.12C291.99 151.67 290.46 149.46 288.55 147.6C286.64 145.74 284.38 144.28 281.91 143.29C279.44 142.3 276.79 141.81 274.12 141.85C271.45 141.88 268.82 142.44 266.37 143.5C263.92 144.55 261.7 146.07 259.84 147.98C257.98 149.89 256.51 152.14 255.53 154.62C254.55 157.07 254.06 159.71 254.09 162.37ZM299.64 161.58C299.76 164.99 299.18 168.4 297.93 171.58C296.68 174.76 294.78 177.65 292.37 180.06C289.95 182.48 287.06 184.37 283.88 185.62C280.7 186.87 277.29 187.45 273.87 187.33C270.6 187.43 267.34 186.86 264.3 185.65C261.26 184.44 258.49 182.63 256.18 180.32C253.87 178.01 252.05 175.25 250.84 172.21C249.63 169.17 249.06 165.92 249.16 162.65C249.04 159.27 249.61 155.9 250.84 152.75C252.07 149.6 253.93 146.73 256.32 144.33C258.7 141.93 261.55 140.03 264.69 138.77C267.83 137.51 271.2 136.9 274.58 136.99C277.88 136.83 281.17 137.35 284.25 138.53C287.33 139.7 290.14 141.5 292.5 143.81C294.85 146.12 296.7 148.89 297.93 151.95C299.16 155.01 299.74 158.28 299.64 161.58Z" fill="#02BEBE" class="svg-elem-5"></path>
    <path id="Vector_6" d="M353.74 174.54C353.74 178.42 353.74 182.21 353.74 186.09H349.15V176.48C347.32 179.83 344.61 182.61 341.31 184.52C338.01 186.43 334.25 187.4 330.43 187.33C324.96 187.33 312.34 185.48 312.34 165.11V138.65H317.19V164.49C317.19 175.69 321.16 183.45 331.75 183.45C335.1 183.45 338.38 182.5 341.21 180.7C344.04 178.9 346.3 176.34 347.72 173.31C348.46 171.52 348.85 169.6 348.87 167.67V138.21H353.81L353.74 174.54Z" fill="#02BEBE" class="svg-elem-6"></path>
    <path id="Vector_7" d="M371.3 149.59C371.3 145.71 371.3 141.92 371.3 138.04H375.89V147.65C377.66 144.31 380.32 141.53 383.57 139.61C386.83 137.69 390.55 136.72 394.33 136.8C398.74 136.8 413.04 138.83 413.04 158.67V185.92H408.45V158.85C408.45 149.5 404.3 141.21 393.36 141.21C389.81 141.25 386.35 142.35 383.44 144.38C380.53 146.41 378.29 149.27 377.03 152.59C376.56 153.98 376.29 155.44 376.24 156.91V186.1H371.3V149.59Z" fill="#02BEBE" class="svg-elem-7"></path>
    <path id="Vector_8" d="M437.32 123.92V138.21H452.59V142.09H437.41V172.78C437.41 178.69 439.18 183.1 445.09 183.1C447.24 183.18 449.39 182.88 451.44 182.22L451.97 186.01C449.64 186.93 447.15 187.38 444.64 187.33C442.99 187.43 441.34 187.15 439.82 186.52C438.3 185.89 436.93 184.93 435.82 183.71C433.44 180.35 432.32 176.26 432.64 172.16V142.09H423.81V138.21H432.64V125.42L437.32 123.92Z" fill="#02BEBE" class="svg-elem-8"></path>
    <path id="Vector_9" d="M464.33 185.74V138.21H469.18V186.1L464.33 185.74ZM470.86 124.01C470.87 124.57 470.77 125.13 470.56 125.66C470.35 126.18 470.04 126.66 469.65 127.06C469.26 127.46 468.78 127.78 468.26 128C467.74 128.22 467.18 128.33 466.62 128.33C465.54 128.26 464.52 127.77 463.79 126.96C463.06 126.15 462.68 125.09 462.73 124.01C462.73 122.92 463.15 121.87 463.91 121.08C464.67 120.29 465.7 119.82 466.79 119.78C467.33 119.79 467.87 119.91 468.37 120.13C468.87 120.35 469.32 120.66 469.7 121.06C470.08 121.46 470.37 121.92 470.57 122.42C470.78 122.92 470.87 123.47 470.86 124.01Z" fill="#02BEBE" class="svg-elem-9"></path>
    </g>
    <g id="fy">
    <path id="Vector_10" d="M483.479 186.1L492.309 142H485.509L486.299 138.21H493.099V135.92C493.789 129.08 496.669 122.65 501.309 117.58C504.099 114.94 507.789 113.46 511.639 113.44C513.999 113.26 516.369 113.78 518.439 114.94L516.939 118.73C515.199 117.91 513.299 117.52 511.379 117.58C503.079 117.58 499.729 125.43 497.789 136.01V138.21H510.059L509.269 142H496.739L487.909 186.1H483.479Z" fill="#275C9A" class="svg-elem-10"></path>
    <path id="Vector_11" d="M515.35 138.21L522.41 166.96C524 173.4 524.88 177.37 525.5 180.72C526.82 177.99 528.41 174.55 531.41 168.64L546.94 138.21H552.06L532.64 175.25C528.69 183.76 523.58 191.68 517.46 198.8C513.44 203.39 508.21 206.75 502.37 208.5L501.13 204.53C504.64 203.37 507.93 201.61 510.84 199.33C515.21 195.72 518.86 191.32 521.61 186.37C521.73 186.08 521.8 185.76 521.8 185.44C521.8 185.12 521.74 184.81 521.61 184.51L509.87 138.21H515.35Z" fill="#275C9A" class="svg-elem-11"></path>
    </g>
    <g id="main_pin">
    <path id="Vector_12" d="M117.09 218.11L99.97 247.74L117.09 264.23L134.21 247.56L117.09 218.11ZM99.96 145.62L117.08 128.86L134.2 145.62L117.08 175.25L99.96 145.62Z" fill="#009CEA" class="svg-elem-12"></path>
    <path id="Vector_13" d="M98.2001 207.35H64.2201L58.3101 230.54L81.3501 236.98L98.2001 207.35ZM152.74 156.29L175.78 162.82L169.87 185.93H135.62L152.74 156.29Z" fill="#275C9A" class="svg-elem-13"></path>
    <path id="Vector_14" d="M135.62 207.35L152.74 236.98L175.78 230.54L169.87 207.35H135.62ZM64.2201 185.92L58.3101 162.81L81.3501 156.28L98.2101 185.91H64.2201V185.92Z" fill="#02BEBE" class="svg-elem-14"></path>
    </g>
    <g id="half_pin">
    <path id="Vector_15" d="M54.8599 107L63.6899 140.07H87.6099V116.17L54.8599 107Z" fill="#009CEA" class="svg-elem-15"></path>
    <path id="Vector_16" d="M34.1199 114.31L9.93994 138.83L22.1199 159.47L43.0399 147.74L34.1199 114.31ZM36.4099 40.23L57.2399 28.5L69.5099 49.05L45.2399 73.3L36.4099 40.23Z" fill="#275C9A" class="svg-elem-16"></path>
    <path id="Vector_17" d="M60.4199 88.3802L93.5199 97.2002L105.26 76.3902L84.6099 64.2202L60.4199 88.3802Z" fill="#02BEBE" class="svg-elem-17"></path>
    </g>
    </g>
    </svg>
    